import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
// import press1 from "../assets/images/press1.png";
// import press2 from "../assets/images/press2.png";

const pressrelease = [
  {
    id: 1,
    desc: "Rincell and Re:Build Manufacturing partner to deliver industry-leading battery packs powered by Rincell's ultra-high-capacity silicon-graphite lithium-ion batteries",
    date: "Dec, 2024",
    path: "https://www.globenewswire.com/news-release/2024/12/03/2990548/0/en/Rincell-and-Re-Build-Manufacturing-partner-to-deliver-industry-leading-battery-packs-powered-by-Rincell-s-ultra-high-capacity-silicon-graphite-lithium-ion-batteries.html",
    // img: press2,
    // alt: "press2",
  },
  {
    id: 2,
    desc: "Rincell awarded $3M grant to accelerate manufacturing of ultra-high capacity lithium-ion batteries in California",
    date: "August, 2024",
    path: "https://www.einnews.com/pr_news/733545915/rincell-awarded-3m-grant-to-accelerate-manufacturing-of-ultra-high-capacity-lithium-ion-batteries-in-california",
    // img: press1,
    // alt: "press1",
  },
  {
    id: 3,
    desc: "Rincell achieves UN38.3 Safety Certification for its high Capacity-Performance 4.1Ah 18650 Silicon-Graphite li-ion Cell",
    date: "June, 2024",
    path: "https://www.einnews.com/pr_news/720437418/rincell-achieves-un38-3-safety-certification-for-its-high-capacity-performance-4-1ah-18650-silicon-graphite-li-ion-cell",
    // img: press1,
    // alt: "press1",
  },
  {
    id: 4,
    desc: "Rincell Corporation raises $1.2M seed round to accelerate the electrification of high impact carbon emission products using advanced cell chemistries",
    date: "Jan, 2024",
    path: "https://www.prnewswire.com/news-releases/rincell-corporation-raises-1-2m-seed-round-to-accelerate-the-electrification-of-high-impact-carbon-emission-products-using-advanced-cell-chemistries-302027747.html",
    // img: press2,
    // alt: "press2",
  },
];

const PressRelease = () => {
  return (
    <Box
      id="newsroom"
      my={2}
      sx={{
        m: { lg: "0rem 3rem", xs: "0rem .5rem", md: "1rem" },
        p: {
          lg: "7.2rem 3rem 0rem 3rem",
          xs: "7.8rem .5rem 0rem .5rem",
          md: "1rem",
        },
      }}
    >
      <Typography
        variant={"maintitle"}
        sx={{
          fontSize: { xs: "2rem", md: "2.2rem" },
          marginBottom: { xs: "1.5rem", md: "2.5rem" },
        }}
      >
        Newsroom
      </Typography>
      <Grid container spacing={4} display={"flex"} flexDirection={"row"}>
        {pressrelease.map((press) => (
          <Grid item xs={12}>
            <Card
              sx={{
                borderRadius: "20px",
                background: "#fff",
                boxShadow: "0px 4px 41px 0px rgba(0, 0, 0, 0.07);",
                padding: "0.7rem",
              }}
            >
              {/* <CardMedia
                sx={{ height: 335, borderRadius: "20px" }}
                image={press.img}
                alt={press.alt}
              /> */}
              <CardContent>
                <Typography
                  variant={"description"}
                  sx={{
                    color: "#232325",
                    fontSize: { xs: "0.9rem", md: "1.2rem" },
                  }}
                >
                  {press.desc}
                </Typography>
                <br />
                <Typography
                  variant={"description"}
                  sx={{
                    color: "#6A6A65",
                    fontSize: { xs: "0.8rem", md: "1.1rem" },
                  }}
                >
                  {press.date}
                </Typography>
              </CardContent>
              <CardActions sx={{ padding: "16px" }}>
                <a href={press.path} target="_blank">
                  <Button
                    size="large"
                    sx={{
                      fontFamily: "Inter,sans-serif",
                      fontSize: { xs: ".5", md: "1rem" },
                      color: "#fff",
                      textAlign: "center",
                      background: "#529077",
                      textTransform: "capitalize",
                      borderRadius: "13px",
                      width: { xs: "7rem", md: "14rem" },
                      "&:hover": { backgroundColor: "#529070" },
                    }}
                  >
                    Read More
                  </Button>
                </a>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PressRelease;
